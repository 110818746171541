import React from "react";
import { StaticQuery, graphql, Link } from "gatsby"

import RegionyTemplate from "../../templates/RegionyTemplate";
import RegionyTemplateV2 from "../../templates/RegionyTemplateV2";



const faqData = [
  {
    otazka: "Co všechno obsahuje cena?",
    odpoved: `Ta obsahuje vyzvednutí zesnulého kdekoliv po Pardubicích a blízkého okolí, převezení do krematoria, samotný žeh a následné uložení do dočasné urny. Doručení urny do vlastních rukou kamkoliv po Pardubicích. V ceně je také administrativa spojená s vyřízením <a href="/slovnik-pojmu/umrtni-list/">úmrtního listu</a> a možnost obracet se na naši zákaznickou linku 24 hodin 7 dní v týdnu.`
  },
  {
    otazka: "Budu platit něco navíc?",
    odpoved: `Poplatky navíc budete platit pouze v případě, kdy by k vyzvednutí zesnulého došlo po více jak 48 hodinách od úmrtí. Ze zákona si potom <a href="/slovnik-pojmu/vyzvednuti-zesnuleho/">nemocnice</a> nebo jiné instituce mají nárok účtovat s tím spojené náklady. Poplatek se sice do finální ceny promítne, ale nám nezůstane. Pošleme ho do dané nemocnice nebo instituce za vás. Podobný poplatek může vystavit krematorium pokud měl pozůstalý více než 100kg a musela by se použít nadměrná rakev.<br/><br/>U nás se s poplatkem setkáte pouze tehdy, když pro zesnulého jedeme mimo dané město. To se pak počítá podle <a href="/slovnik-pojmu/cena-za-dopravu/">najetých kilometrů</a>.`
  },
  {
    otazka: "Kdy a jak se platí?",
    odpoved: "Platba zálohy probíhá vždy předem. Je možné ji provést buď bankovním převodem nebo online přes platební bránu. Fakturu s údaji o platbě obdržíte po vytvoření závazné objednávky. Po pohřbu zákazník obdrží druhou fakturu za zbytek částky která není obsažená v záloze. Například v případě, že využijete i některých dalších služeb které nabízíme: jiná urna, květiny a podobně. Moc rádi vám v případě zájmu zařídíme i platbu na splátky skrz naše partnery. Pro více informací se neváhejte obrátit na naši pomocnou linku 315 558 136."
  },
  {
    otazka: "Je možné platit na splátky?",
    odpoved: "Ano, u nás můžete platit na splátky. Prostřednictvím našich partnerů jsme vám schopni splácení faktury zařídit do 24 hodin. Pro více informací se neváhejte obrátit na naši pomocnou linku 315 558 136."
  },
  {
    otazka: "Jaké dokumenty budu potřebovat?",
    odpoved: `Pokud je zesnulý doma, budete k předání pohřební službě potřebovat <a href="/slovnik-pojmu/list-o-prohlidce-tela/">ohledací list</a>, který vystaví Zdravotnická záchranná služba nebo <a href="/slovnik-pojmu/koroner/">koroner</a>. Pokud je v nemocnici nebo pečovatelském domě, postarají se o vystavení dokumentu i předání pohřební službě příslušní zaměstnanci. Dále od vás budeme potřebovat <span>kopii vašeho občanského průkazu</span>, občanský průkaz zesnulé/ho a <span>plnou moc</span>, kterou s vámi podepíšeme online. Ta se musí vystavit proto, abychom mohli manipulovat s tělem zesnulého.`
  },
  {
    otazka: "Poskytujete služby po celé ČR?",
    odpoved: `V současné chvíli poskytujeme pohřební služby téměř po celé ČR. Konkrétní město si můžete zkontrolovat v naší sekci <a href="/pobocky/">pobočky</a>. Pokud vám jde o lokalitu jinou, i tak se na nás neváhejte obrátit. V případě potřeby jsme schopni zajet kamkoliv.`
  },
  {
    otazka: "Jak si můžu být jistý/á, že nedojde k záměně popela?",
    odpoved: `Krematorium je ze zákona o pohřebnictví povinno do <a href="/rakve/">rakve</a> vkládat kovový plíšek s označením. Ten není během kremace poškozen a následně je i s popelem vložen do stejně označené <a href="/poradna/kremacni-popel/">urny</a>. Krematorium vede o celém procesu podrobnou evidenci, takže záměna je vyloučena.`
  },
];



const Pardubice = ({ data }) => {

  return (
    <StaticQuery
      query={graphql`
        query {
          jumboObrazek: file(relativePath: { eq: "jumboObrazek.png" }) {
            childImageSharp {
              fixed(width: 460) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }

          jumboObrazekMobile: file(relativePath: { eq: "jumboObrazekMobile.png" }) {
            childImageSharp {
              fixed(width: 460) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }

          velka: file(relativePath: { eq: "velkaPardubice.jpeg" }) {
            childImageSharp {
              fluid(maxWidth: 440) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }

          mala: file(relativePath: { eq: "malaPardubice.jpeg" }) {
            childImageSharp {
              fluid(maxWidth: 440) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }

          dubina: file(relativePath: { eq: "pardubice-dubina.jpeg" }) {
            childImageSharp {
              fluid(maxWidth: 440) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      `}
      render={data => ( <>
        <RegionyTemplateV2
          name="Pohřební služba Pardubice"
          slug="pohrebni-sluzba-pardubice"
          faqData={faqData}

          seoTitle="Pohřební služba Pardubice - Empatická & Dostupné ceny (24/7)"
          seoDesc="Nejlépe hodnocená pohřební služba v Pardubicích: Důstojné pohřby s obřadem, kremace bez obřadu. Sjednání z domova Nonstop. Nízké ceny od 13 900 Kč"
          ratingCount={35}

          h1="Pohřební služba Pardubice"
          perex={<>Naplánujeme s vámi jedinečný pohřeb v Pardubicích z bezpečí domova a za předem stanovenou cenu. Přesně takové rozloučení, jaké by si váš blízký přál.<br/><br/>Postaráme se o vše potřebné, abyste se vy a vaše rodina mohli věnovat v těchto těžkých časech především jeden druhému.</>}
          jumboObrazek={data.jumboObrazek.childImageSharp.fixed}
          jumboObrazekMobile={data.jumboObrazekMobile.childImageSharp.fixed}
          reviewDesc="Nejlépe hodnocená pohřební služba v Pardubicích za rok 2024"

          sklonovaneMesto="pardubických"

          introH2="V čem jsme jiní než ostatní pohřební služby v Pardubicích?"
          introText={<>Řešení úmrtí blízkého je možná ta nejtěžší situace v životě člověka: plná stresu, zmatku a smutku. Sami jsme se v minulosti setkali s neempatickým přístupem několika pohřebních služeb. Vypravení pohřbu bylo chladné, neosobní a vždy za neúměrně vysokou cenu. To jsme se rozhodli změnit.<br/><br/>Založili jsme Goodbye s důrazem na osobní přístup a transparentnost – jako pohřební službu, kterou bychom s klidným srdcem nabídli vlastní rodině a přátelům. U nás nemusíte nikam chodit. Celý pohřeb si nezávazně sestavíte přes internet a rovnou se dozvíte konečnou cenu. Náš empatický tým odborníků vám následně pomůže uspořádat jedinečné poslední rozloučení v <Link to="/pohreb-s-obradem/">tradiční</Link> či <Link to="/netradicni-pohreb/">alternativní</Link> podobě pohřbu.</>}

          procesDesc="V klidu vás provedeme celým procesem a zařídíme, co je potřeba. Věříme, že si každý zaslouží důstojné poslední rozloučení. Uděláme vše pro to, abychom vám a vaší rodině pomohli uspořádat v Pardubicích to pravé."
          procesText1={<>Zajistíme převoz vašeho blízkého do našeho pardubického zázemí. Může se jednat i o <Link to="/slovnik-pojmu/vyzvednuti-zesnuleho/">akutní vyzvednutí z domu</Link>, nemocnice nebo jiné pohřební služby.</>}
          procesText2={<>Pomůžeme vám naplánovat osobní rozloučení podle vašich přání. Může se jednat o <Link to="/pohreb-s-obradem/">tradiční pohřeb v krematoriu</Link>, <Link to="/kremace-bez-obradu/">kremaci bez obřadu</Link> nebo i <Link to="/netradicni-pohreb/">netradiční variantu</Link>.</>}
          procesText3={<>Zajistíme pietní a důstojnou <Link to="/poradna/kremace-zajimavosti/">kremaci</Link> v našem partnerském krematoriu. Mezi tím za vás vyřešíme veškerou dokumentaci a <Link to="/slovnik-pojmu/umrtni-list/">úmrtní list</Link> na matrice.</>}
          procesText4={<>Urnu si u nás můžete vyzvednout nebo vám ji přivezeme až domů. Popel potom můžete <Link to="/slovnik-pojmu/rozptyl-vsyp-popela/">rozptýlit</Link>, <Link to="/slovnik-pojmu/hrob/">uložit do kolumbária</Link> či například <Link to="/poradna/kremacni-popel/">zasadit strom</Link>. Je to jen na vás.</>}

          kremaceText="Postaráme se o vyzvednutí zesnulého a následnou kremaci. Urnu si za cca 14 dní budete moci vyzvednout na pobočce. Samotná forma rozloučení je potom jen na vás."
          kremaceCena="15 900"
          kremaceSluzby={[
            "Sjednání za pár minut přes internet",
            "Vyřízení veškerých dokumentů",
            "Tvorba parte",
          ]}

          pohrebCena="23 990"
          pohrebSluzby={[
            "Řečník, hudba, prezentace fotek",
            "Vyřízení veškerých dokumentů",
            "20-minutový obřad v Pardubicích"
          ]}

          mestoH2="Máte nějaký speciální požadavek v Pardubicích?"
          mestoText={<>
            Samozřejmě vám se vším rádi pomůžeme. Zavolejte nám a naši sjednavatelé se s vámi domluví na <Link to="/poradna/smutecni-kytice-pohreb/">výzdobě</Link>, <Link to="/poradna/smutecni-rec-rady-tipy/">řečníkovi</Link>, <Link to="/poradna/pruvodce-tvorbou-smutecniho-oznameni/">parte</Link> nebo čemkoli jiném, co byste si na <Link to="/poradna/jak-naplanovat-pohreb/">pohřbu přáli</Link>.<br/><br/>
            Samozřejmostí je vyzvednutí vašeho blízkého pohřební službou kdekoliv po Pardubicích a okolí, ať už se zesnulý nachází v Pardubické nemocnici, Domově pro seniory Dubina, <Link to="/pohrebni-sluzba-hradec-kralove/">Hradci Králové</Link> nebo kdekoli jinde. V případě <Link to="/slovnik-pojmu/vyzvednuti-zesnuleho/">úmrtí doma</Link> jsme na místě do několika hodin od prvního telefonátu. Provádíme i akutní vyzvednutí mimo pracovní dobu nebo o víkendech a svátcích. Doba příjezdu se může mírně lišit v návaznosti na konkrétní vzdálenost. Ze zkušenosti jsme ale na místě nejpozději do 3 hodin od zavolání, ať se jedná o Pardubice-Střed, Polabiny, <Link to="/pohrebni-sluzba-pardubice/dubina/">Dubina</Link> nebo Svítkov.<br/><br/>
            Pokud využíváte služeb <Link to="/seznam-hospicu/">domácího hospice</Link>, je možné se s námi spojit dopředu a domluvit se na postupu. V takovém případě vám v momentě odchodu vašeho blízkého budeme schopni situaci co možná nejvíce ulehčit.<br/><br/>
            Nabízíme různé typy pohřebních služeb - kremace Pardubice: pohřeb v několika pardubických <Link to="/obradni-sine/">obřadních síních</Link>, kremaci bez obřadu a netradiční rozloučení v přírodě. Máme také širokou nabídku <Link to="/rakve/">rakví</Link>, <Link to="/poradna/smutecni-kytice-pohreb/">květin</Link>, věnců a dalších <Link to="https://www.prozeny.cz/clanek/pohrebnicke-trendy-miri-do-ceska-obrad-naplanujete-z-pohodli-domova-81281">smutečních předmětů</Link>.
          </>}
          mapLocation={"Pardubice"}

          featuresH2="Profesionalita a důstojnost je pro nás na prvním místě"
          featuresText1={<>Ať váš blízký <Link to="/slovnik-pojmu/vyzvednuti-zesnuleho/">zemřel doma</Link>, v Pardubické nemocnici, v domově pro seniory U Kostelíčka nebo kdekoli jinde, nemusíte nikam chodit. Vše vyřídíte z bezpečí vašeho domova, kde si můžete detaily posledního rozloučení v klidu rozmyslet a probrat je s rodinou.</>}
          featuresText2="Cena, na které se s naší pohřební službou domluvíte, je vždy konečná. Obsahuje kompletně vše, co budete potřebovat, včetně paušálních kilometrů po celých Pardubicích."
          featuresText3="Chápeme, že úmrtí blízké osoby je nečekaná událost. Proto jsme připraveni ji s vámi řešit v čase, který vyhovuje vám a vaší rodině. Ať už to bude v 10 hodin večer, až půjdou děti spát, nebo během sobotního odpoledne, kdy nebudete rušeni pracovními hovory. Ozvat se nám můžete 24 hodin 7 dní v týdnu."
          
          seVsimPomuzemeText="Úmrtí člena rodiny je velmi náročnou situací pro všechny pozůstalé. Zavolejte nám a my jsme připraveni vaši situaci okamžitě řešit. Se vším vám pomůžeme a vytvoříme pro vás a vaše blízké to nejosobnější poslední rozloučení v Pardubicích a okolí."
          
          obradniSineH2="Pardubické obřadní síně"
          obradniSine={[
            {
              h3: "Malá obřadní síň krematoria Pardubice",
              img: data.mala.childImageSharp.fluid,
              link: "/obradni-sine/mala-obradni-sin-krematorium-pardubice/",
              text: <>Malá obřadní síň krematoria Pardubice je součástí areálu hřbitova Pardubice, který se nachází na adrese Pod Břízkami 990. Vchod do malé obřadní síně se nachází napravo pod hlavním schodištěm. Její maximální kapacita je 12 osob, je tedy vhodná pouze pro komorní rozloučení v úzkém rodinném kruhu. Uprostřed síně je pevný katafalk. Obřady trvají cca 20 minut a jejich součástí je přehrání 4 reprodukovaných písní dle vlastního výběru a případně krátká promluva řečníka.</>
            },
            {
              h3: "Velká obřadní síň krematoria Pardubice",
              img: data.velka.childImageSharp.fluid,
              link: "/obradni-sine/velka-obradni-sin-krematorium-pardubice/",
              text: <>Vchod do velké obřadní síně se nachází uprostřed po výstupu schodiště. Pro bezbariérový přístup je zprava budovy umístěn výtah. Uprostřed síně je katafalk, nad kterým je obrazovka připravená pro případné <Link to="/slovnik-pojmu/prezentace-fotek/">promítání fotografií</Link>. Obřady trvají zhruba 20 minut a jejich součástí je přehrání 4 písní dle vlastního výběru a krátká promluva řečníka.</>
            },
          ]}

          dalsiOdkazy={[
            {
              h4: "Pohřební služba Pardubice: Dubina",
              p: "Pokud hledáte pohřební službu v Pardubicích Dubině nebo v blízkosti Pardubické nemocnice.",
              link: "/pohrebni-sluzba-pardubice/dubina/",
              img: data.dubina.childImageSharp.fluid,
            },
          ]}
        />
      </>)}
    />
  );
}

export default Pardubice;


